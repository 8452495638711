import React from 'react'
import AboutBackgroundImage from "../Assets/aboutwomen.jpg"
import { FiArrowRight} from "react-icons/fi"
import { BsFillPlayCircleFill } from 'react-icons/bs'

//temporarily meeting notes but this is supposed to be about page
const About = () => {
  const handleButtonClick = () => {
    window.location.href = "https://docs.google.com/document/d/1TASrkjXGSI5Om9kvdAQoiwHd-CbQjDDLfT1oxwv9tJs/edit"
  }
  return (
    <div className="about-section-container">
        <div className="about-section-image-container">
            <img src={AboutBackgroundImage} alt="" />

        </div>
        <div className="about-section-text-container">
            <p className="primary-subheading">Meeting notes</p>
            <h1 className="primary-heading">
                These are our notes
            </h1>
            <p className="primary-text">
                blahblahblah
            </p>
            <div className="about-buttons-container" >
                <button className="secondary-button" onClick={handleButtonClick}>
                    Meeting Notes <FiArrowRight />
                </button>
            </div>
        </div>
    </div>
  )
}

export default About

