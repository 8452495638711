import React, { useState } from 'react';
import Logo from "../Assets/logo.png";
import { BsCardList, BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import List from "@mui/material/List"
import { 
    Box, 
    Drawer, 
    ListItem, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home"
import InfoIcon from "@mui/icons-material/Info"
import AssignmentIcon from "@mui/icons-material/Assignment"
import CreateIcon from "@mui/icons-material/Create"

//navbar compatible w other devices
const Navbar = () => {

    const [openMenu, setOpenMenu] = useState(false)
    const menuOptions = [
        {
            text:"Home",
            icon:<HomeIcon/>
        },
        {
            text: "About",
            icon:<InfoIcon/>
        },
        {
            text:"Projects",
            icon:<AssignmentIcon/>
        },
        {
            text:"SRS",
            icon:<CreateIcon/>
        },
    ];
    
    const openPdf = () => {
        window.open('notes.pdf', '_blank');
    };



    return (
        <nav>
            <div className="nav-logo-container" >
                <img src={Logo} alt="" />
            </div>
            <div className="navbar-links-container" >
                <a href="">Home</a>
                <a href="">About</a>
                <a href="">Projects</a>
                <a href="">SRS</a>
                <a href="">
                    <BsCardList className="navbar-notes-icon" />
                </a>
                <button className="primary-button" onClick={openPdf}>Notes</button>
            </div>
            <div className="navbar-menu-container" >
                <HiOutlineBars3 onclick={() => setOpenMenu(true)} />
            </div>
            <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right" >
                <Box 
                sx={{ width: 250}}
                role="presentation"
                onClick={() => setOpenMenu(false)}
                onKeyDown={() => setOpenMenu(false)}
                >
                    <List>
                        {menuOptions.map((item) => (
                            <ListItem key={item.text} disablePadding >
                                <ListItemButton>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary ={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
    </nav>
    );
};

export default Navbar;
