import React from 'react'
import EqualityWatch from "../Assets/equalitywatch.jpg"
import AllyScanner from "../Assets/allyscanner.jpg"
import EqualityShoes from "../Assets/equalityshoes.png"
const Projects = () => {
    const ProjectInfo = [
        {
            image: EqualityWatch,
            title: "Equality Watch",
            text: "Not sure if its time for equality? Buy our watch and you'll know with just a glance at your wrist!",
        },
        {
            image: AllyScanner,
            title: "Ally Scanner",
            text: "Not sure if someone is a real ally? Check them with our Ally Scanner!",
        },
        {
            image: EqualityShoes,
            title: "Equality Shoes",
            text: "Every step in these is a step towards equality."
        },
    ]
  return (
    <div>
      Projects
    </div>
  )
}

export default Projects
