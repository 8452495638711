import React from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import BannerImage from "../Assets/together.jpg"
import { FiArrowRight} from "react-icons/fi"
const Home = () => {

    const handleButtonClick = () => {
        window.location.href = "https://docs.google.com/document/d/1mAdtR01VoAvofysuyGs24P-4GhdCGC-LSvhv47D3YlE/edit"
    }

    return (
        <div className="home-container">
            <Navbar />
            <div className="home-banner-container" >
                <div className="home-text-section" >
                    <h1 className="primary-heading" >
                        The Future Is Female. Period.
                    </h1>
                    <p className="primary-text" >
                        At Feminism Incorporated we believe every little girl is a girlboss.
                    </p>
                    <button className="secondary-button" onClick={handleButtonClick}>
                        View our SRS <FiArrowRight />
                    </button>
                </div>
                <div className="home-image-container" >
                    <img src={BannerImage} alt="" />
                </div>

            </div>

        </div>
    )
}

export default Home